<template>
  <v-app
    class="fill-height"
    fluid
  >
    <v-app-bar
      dense
      elevation="1"
      app
    >
      <v-img
        v-if="lab_result && lab_result.client"
        :src="lab_result.client.logo"
        :contain="true"
        :height="34"
        class="mt-1"
        width="30%"
        position="left"
      />
      <v-spacer />
      <!-- <v-col>
          <v-img
            :src="$vuetify.breakpoint.mobile ? '/img/onechoice-plus-logo-icon.svg' : '/img/onechoice-plus-logo-name.svg'"
            :contain="true"
            :height="18"
            swidth="40%"
            xposition="right"
          />
        </v-col>
        <v-spacer /> -->
      <language-switcher
        :append-icon="null"
        :style="$vuetify.breakpoint.mobile ? 'max-width: 72px;': 'max-width: 124px;'"
        class="text-caption mb-n7 xmt-1 xpx-0"
        dense
        flat
        prepend-icon="fad fa-fw fa-language"
        solo-inverted
      />
    </v-app-bar>
    <v-main class="grey lighten-5">
      <v-container fluid>
        <v-overlay
          :value="loading.result"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            size="150"
            width="10"
          />
        </v-overlay>
        <v-row
          v-if="!loading.result && lab_result && lab_result.external_id"
        >
          <v-col
            cols="12"
            md="9"
          >
            <v-row class="match-height">
              <v-col
                md="4"
              >
                <v-card>
                  <div>
                    <v-card-title
                      class="d-flex justify-space-between indigo darken-3 white--text"
                      background-color="indigo darken-3"
                      text-color="white"
                    >
                      <div>{{ $tc('Lab Result', 1) }} <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span></div>
                      <source-chip
                        :name="lab_result.sample_type"
                        :source="lab_result.sample_source"
                        :outlined="false"
                        color="indigo lighten-5"
                        xtext-color="white"
                        small
                        light
                        flat
                      />
                    </v-card-title>
                    <v-card-subtitle class="indigo darken-3 white--text">
                      <template
                        v-if="lab_result.patient.uuid"
                      >
                        {{ $t(lab_result.patient.gender + ' patient') | capitalize }}, {{ patient_age }} {{ $tc('year old', patient_age) }}
                      </template>
                      <template v-else>
                        {{ $tc('patient', 1) | capitalize }} XXXX, XX {{ $tc('year old', patient_age) }}
                      </template>
                      <v-hover
                        :value.sync="hover"
                        disabled
                      >
                        <v-btn
                          :loading="loading.pdf"
                          color="info darken-2"
                          fab
                          right
                          absolute
                          small
                          class="mt-4"
                          @click="fetchPdf"
                        >
                          <i class="fas fa-file-download fa-lg" />
                        </v-btn>
                      </v-hover>
                    </v-card-subtitle>
                  </div>
                  <live-factor
                    :value="lab_result"
                    :selected-drug="selected_drug"
                    :patient-age="patient_age"
                    color="cyan lighten-5"
                  />
                </v-card>
              </v-col>
              <v-col
                v-if="lab_result.interpretation"
                cols="12"
                md="8"
              >
                <v-expansion-panels
                  focusable
                  accordion
                  :value="1"
                >
                  <v-expansion-panel
                    class="purple darken-3"
                  >
                    <v-card-title
                      class="branded"
                    >
                      <v-img
                        src="/img/onechoice-logo-white.svg"
                        :contain="true"
                        xheight="20"
                        :max-width="150"
                      />
                    </v-card-title>
                    <v-card-text class="white--text text-h6">
                      <template v-if="lab_result.interpretation.one_choice">
                        <treatment-regimen
                          :treatment="lab_result.interpretation.one_choice"
                          show-indication
                          @drug-click="v => selected_drug = v"
                        />
                      </template>
                      <span v-else>
                        {{ one_choice }}
                      </span>
                    </v-card-text>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ questions.alternatives }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="mt-4 ps-0"
                    >
                      <ul
                        v-if="lab_result.interpretation.alternatives.length > 0"
                        class="ml-n3"
                      >
                        <treatment-regimen
                          v-for="(treatment, index) in lab_result.interpretation.alternatives"
                          :key="index"
                          :treatment="treatment"
                          show-arkscore
                          :show-indication="showIndication"
                          @drug-click="v => selected_drug = v"
                        />
                      </ul>
                      <span
                        v-else-if="instructions.alternatives"
                        v-html="instructions.alternatives[$i18n.locale] || instructions.alternatives.en"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-overlay
                    v-if="disableOneChoice"
                    absolute
                    color="purple darken-3"
                    :overlay="false"
                    style="backdrop-filter: blur(3px);"
                  >
                    <v-alert
                      color="primary"
                      class="mx-10"
                    >
                      {{ $t('live_factor.modified') }}
                    </v-alert>
                  </v-overlay>
                </v-expansion-panels>

                <v-card>
                  <v-card-title
                    class="purple darken-3 branded mt-6"
                  >
                    <v-img
                      src="/img/meds-matrix-logo-white.svg"
                      :contain="true"
                      xheight="20"
                      :max-width="150"
                    />
                  </v-card-title>
                  <treatment-table
                    v-if="lab_result.organisms"
                    id="treatment-matrix"
                    :key="lab_result.match_hash"
                    :source="lab_result.sample_source"
                    :organisms="detected_pathogens.length ? detected_pathogens : detected_organisms.filter(x => x.controversial)"
                    :resistance="lab_result.resistance"
                    :allergies="lab_result.allergies"
                    :pregnant="lab_result.pregnant"
                    :pediatric="pediatric"
                    :loading="reload_matrix"
                    :selected-drug="selected_drug"
                    clickable-drugs
                    width="100%"
                    @resize="matrix_height = $event"
                    @drug-click="v => selected_drug = v"
                  />
                </v-card>
              </v-col>

              <v-col>
                <v-expansion-panels
                  focusable
                  accordion
                >
                  <v-expansion-panel
                    v-for="instruction, key in filtered_instructions('alternatives')"
                    :key="key"
                  >
                    <v-expansion-panel-header
                      ripple
                      class="text-body-1"
                    >
                      {{ questions[key] }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-4">
                      {{ instruction[$i18n.locale] || instruction.en }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col>
                <v-expansion-panels
                  focusable
                  accordion
                  dense
                >
                  <v-expansion-panel>
                    <v-card-title class="text-overline text-no-wrap pb-0">
                      <v-icon
                        class="me-1"
                        small
                      >
                        fal fa-fw fa-viruses
                      </v-icon>
                      {{ $t('Organisms Detected') }}
                    </v-card-title>

                    <v-card-text class="pa-0">
                      <arkscore-card
                        :name="$t('arkscore.infection_complexity')"
                        :score="lab_result.score_infection_complexity"
                        :bar-height="9"
                        class="px-0"
                        blurb="The combination of organisms and resistance detected affect the level of difficulty in treating the infection. The more complex the infection, the less treatment options are available."
                        flat
                      />
                    </v-card-text>
                    <v-card-subtitle
                      v-if="lab_result.organisms.filter(x => x.result.detected && x.significant).length > 0"
                      class="font-italic text-caption pt-0 mt-n1"
                    >
                      {{ $t('organisms_detected.subtitle', { source: $t(lab_result.sample_source) }) }}
                    </v-card-subtitle>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-for="(organism, index) in orderBy(lab_result.organisms.filter(x => x.result.detected), 'name')"
                    :key="index"
                  >
                    <v-expansion-panel-header
                      :class="{ 'font-weight-bold': organism.significant }"
                    >
                      {{ organism.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="mt-4">
                        {{ organism.description[$i18n.locale] }}
                      </p>
                      <template v-if="organism.infections.length">
                        <div class="text-overline mt-3">
                          {{ $t('Commonly Associated Infections') }}
                        </div>
                        <ul>
                          <li
                            v-for="(infection, id) in organism.infections"
                            :key="id"
                          >
                            {{ infection.name }}
                          </li>
                        </ul>
                      </template>
                      <template v-if="organism.effective_drugs">
                        <div class="text-overline mt-3">
                          {{ $t('Drugs with Known Activity') }}
                        </div>
                        <ul class="ms-n2">
                          <li
                            v-for="(drug, id) in organism.effective_drugs"
                            :key="id"
                          >
                            {{ drug.name[$i18n.locale] || drug.name.en }}
                          </li>
                        </ul>
                      </template>
                      <template v-if="organism.references && organism.references.length">
                        <div class="text-overline mt-3">
                          {{ $tc('reference', 2) }}
                        </div>
                        <reference-links
                          :references="organism.references"
                        />
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col v-if="resistance.length">
                <v-expansion-panels
                  focusable
                  accordion
                >
                  <v-expansion-panel>
                    <v-card-title class="text-overline text-no-wrap pb-0">
                      <v-icon
                        class="me-1"
                        small
                      >
                        fal fa-fw fa-shield-virus
                      </v-icon>
                      {{ $t('Detected Antibiotic Resistance') }}
                    </v-card-title>
                    <v-card-text class="pa-0">
                      <arkscore-card
                        :name="$t('arkscore.antimicrobial_resistance')"
                        :score="lab_result.score_antimicrobial_resistance"
                        :bar-height="9"
                        blurb="This is a measure of the level of resistance detected. Higher scores indicate that the detected resistance affects more drug classes, thereby limiting the number of effective treatment options. The lower the score, the less resistant the patient will be to antibiotic therapy."
                        flat
                      />
                    </v-card-text>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-for="(resistance, index) in orderBy(resistance, 'name.en')"
                    :key="index"
                  >
                    <v-expansion-panel-header ripple>
                      {{ resistance.name[$i18n.locale] || resistance.name.en }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="mt-4">
                        {{ resistance.description }}
                      </p>
                      <template v-if="resistance.drugs.length">
                        <div class="text-overline mt-3">
                          {{ $t('Drugs in this Category') }}
                        </div>
                        <ul>
                          <li
                            v-for="(drug, id) in resistance.drugs"
                            :key="id"
                          >
                            {{ drug.name[$i18n.locale] || drug.name.en }}
                          </li>
                        </ul>
                      </template>
                      <template v-if="resistance.references && resistance.references.length">
                        <div class="text-overline mt-3">
                          {{ $tc('reference', 2) }}
                        </div>
                        <reference-links
                          :references="resistance.references"
                        />
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col md="auto">
                <v-card>
                  <v-card-title
                    :key="lab_result.allergies?.length ? 'fa-allergies' : 'fa-hand-dots-slash'"
                    class="text-overline text-no-wrap"
                  >
                    <v-icon
                      class="me-1"
                      small
                    >
                      fa-fw {{ lab_result.allergies?.length ? 'fa-light fa-allergies' : 'fa-kit fa-light-hand-dots-slash' }}
                    </v-icon>
                    {{ $t(lab_result.allergies?.length ? 'Allergies Reported' : 'No Allergies Reported') }}
                  </v-card-title>
                  <v-divider />
                  <v-list
                    v-if="lab_result.allergies?.length"
                    dense
                  >
                    <v-list-item
                      v-for="allergy in lab_result.allergies"
                      :key="allergy.name"
                    >
                      {{ allergy.name }}
                    </v-list-item>
                  </v-list>
                </v-card>

                <v-card
                  v-if="lab_result.pregnant"
                  class="mt-5"
                >
                  <v-card-title class="text-overline text-no-wrap">
                    <v-icon
                      class="me-1"
                      small
                    >
                      fal fa-fw fa-person-pregnant
                    </v-icon>
                    {{ $t('Pregnancy Reported') }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="3"
          >
            <!-- <div
                v-if="lab_result.interpretation.one_choice || (lab_result.interpretation.drugs && lab_result.interpretation.drugs.length)"
                class="mt-6 mb-2 text-overline"
              >
                <v-icon small>
                  fal fa-fw fa-pills
                </v-icon>
                {{ $t('drug_info.title') }}
              </div> -->
            <drug-info
              v-if="selected_drug"
              :key="selected_drug.id"
              :drug="selected_drug"
              :patient="lab_result.patient"
              :resistances-tested="lab_result.resistances_tested"
              show-name
              show-reference-dosages
              show-ark-score-bar
              card
            />
            <!-- <v-card-text
              v-else
            >
              Select a drug in the MedsMatrix to see detailed info
            </v-card-text>
          </v-expansion-panels> -->
            <!-- <v-expansion-panels
            focusable
            accordion
            :value="0"
          >
            <template
              v-if="lab_result.interpretation.one_choice"
            >
              <v-expansion-panel
                v-for="(regimen, index) in lab_result.interpretation.one_choice.regimens"
                :key="index"
              >
                <v-expansion-panel-header
                  ripple
                  class="text-body-1"
                >
                  {{ regimen.drug.name.en }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <drug-info
                    :drug="regimen.drug"
                    :reference-dosage="regimen.standard_dosage"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
            <template
              v-else
            >
              <v-expansion-panel
                v-for="(drug, index) in lab_result.interpretation.drugs"
                :key="index"
              >
                <v-expansion-panel-header
                  ripple
                  class="text-body-1"
                >
                  {{ drug.name[$i18n.locale] || drug.name.en }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <drug-info
                    :drug="drug"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-caption text-justify">
            {{ $t('onechoice_disclaimer', { patient: $t(lab_result?.patient?.gender + ' patient') }) }}
            {{ $t('onechoice_disclaimer2') }}
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      app
      color="primary darken-2"
      class="py-3"
      style="z-index:5"
      dark
    >
      <v-row justify="space-between">
        <v-col
          cols="auto"
          class="hidden-sm-and-down"
        >
          <h5>
            <v-img
              src="/img/arkstone-logo-name-white.svg"
              :contain="true"
              :width="120"
              position="left"
            />
          </h5>
        </v-col>
        <v-col
          cols="auto"
          class="align-center"
        >
          <span class="text-body-1">
            <span class="hidden-sm-and-down">
              Have questions? Visit our
            </span>
            <a
              style="color:#fff; text-decoration: none;"
              href="http://support.arkstonemedical.com/support/solutions"
              target="_blank"
            >
              Knowledge Base
              <v-icon
                key="fal fa-arrow-up-right-from-square fa-fw"
                x-small
                class="mt-n1"
              >
                fal fa-arrow-up-right-from-square fa-fw
              </v-icon>
            </a>
          </span>
        </v-col>
        <v-col
          cols="auto"
        >
          <span class="text-body-1">
            1-833-933-ARK-3
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    name: 'Report',
    metaInfo () {
      return {
        title: this.$tc('Lab Result') + ' ' + (this.lab_result.external_id ?? ''),
        titleTemplate: '%s | OneChoice PLUS',
      }
    },
    components: {
      SourceChip: () => import('@/components/source/SourceChip.vue'),
      ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
      TreatmentRegimen: () => import('@/components/interpretation/TreatmentRegimen.vue'),
      TreatmentTable: () => import('@/components/interpretation/TreatmentTable.vue'),
      DrugInfo: () => import('@/components/drug/DrugInfo.vue'),
      ReferenceLinks: () => import('@/components/reference/ReferenceLinks.vue'),
      LiveFactor: () => import('@/components/interpretation/LiveFactor.vue'),
      LanguageSwitcher: () => import('@/components/selects/LanguageSwitcher.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    data: () => ({
      icon: '/img/arkstone-logo.svg',
      loading: {
        result: true,
        pdf: false,
      },
      reload_matrix: false,
      valid: true,
      lab_result: {},
      original_lab_result: {},
      translations: {},
      selected_drug: {},
      question_order: [
        'alternatives','one_choice_explained','treated_when','considerations','treated_length','infection_control',
      ],
      hover: false,
    }),
    computed: {
      disableOneChoice () {
        // compare if the contents of the resistance array are the same
        return JSON.stringify(this.lab_result.resistance.map(x => x.name.en)) != JSON.stringify(this.original_lab_result.resistance.map(x => x.name.en)) ||
          JSON.stringify(this.lab_result.organisms.map(x => x.name)) != JSON.stringify(this.original_lab_result.organisms.map(x => x.name)) ||
          JSON.stringify(this.lab_result.allergies.map(x => x.name)) != JSON.stringify(this.original_lab_result.allergies.map(x => x.name)) ||
          this.lab_result.pregnant != this.original_lab_result.pregnant
      },
      questions () {
        var questions = this.$t('questions')
        // if (this.lab_result.interpretation.instructions.one_choice_explained) {
        //   delete questions.treated_length
        // } else {
        //   delete questions.one_choice_explained
        // }
        return questions
      },
      one_choice () {
        let str = ''
        if (this.lab_result.interpretation.one_choice) {
          str = this.treatmentString(this.lab_result.interpretation.one_choice)
        } else {
          var drugs = ''
          Object.values(this.lab_result.interpretation.drugs).forEach((drug) => {
            drugs += drug.name.en + ' ' + drug.impression.dosage
          })
          if (this.lab_result.interpretation.drugs && this.lab_result.interpretation.drugs.length) {
            drugs += ' x '
          }
          str = drugs + this.lab_result.interpretation.impression_duration
        }
        if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale]) {
          return this.translations[this.$i18n.locale].one_choice
        } else {
          return str
        }
      },
      uuid () {
        return Object.keys(this.$route.query)[0]
      },
      fileName () {
        var filename
        if (this.lab_result.external_id) {
          filename = this.lab_result.external_id + ' - ' + this.lab_result.sample_type + ' - Arkstone.pdf'
        }
        return filename
      },
      instructions () {
        let instructions = this.lab_result.interpretation.instructions
        // if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale]) {
        //   instructions = this.translations[this.$i18n.locale].instructions
        // } else {
        //   instructions = this.lab_result.interpretation.instructions
        // }

        return Object.keys(instructions)
          .sort((a, b) => this.question_order.indexOf(a) - this.question_order.indexOf(b))
          .reduce((acc, key) => ({ ...acc, [key]: instructions[key].instruction }), {})
      },
      resistance () {
        var lab_result_resistance = this.lab_result.resistance.filter((value, index, self) => self.findIndex(obj => obj.name === value.name) === index)
        if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale] && this.translations[this.$i18n.locale].resistance) {
          var resistance = []
          lab_result_resistance.forEach((x) => {
            x.description = this.translations[this.$i18n.locale].resistance[x.name.en]
            resistance.push(x)
          })
          return resistance
        } else {
          return lab_result_resistance
        }
      },
      detected_organisms () {
        return this.lab_result.organisms.filter(x => x.result.detected)
      },
      detected_pathogens () {
        return this.detected_organisms.filter(x => x.significant)
      },
      patient_age() {
        return this.$moment(this.lab_result.created_at).diff(this.lab_result.patient.dob, 'years')
      },
      pediatric () {
        return this.patient_age < 18
      },
      showIndication () {
        var indication
        if (this.lab_result.interpretation.one_choice) {
          indication = this.lab_result.interpretation.one_choice.indication.indication
        } else {
          indication = this.lab_result.interpretation.impression_duration.split('for possible').pop()
        }
        return this.lab_result.interpretation.alternatives.filter(x => x.indication.indication != indication).length > 0
      },
    },
    watch: {
      hover () {
        this.fetchPdf()
      },
      translations () {
        this.$forceUpdate()
      },
      '$i18n.locale' () {
        let instructions = this.lab_result.interpretation.instructions
        let toTranslate = []
        Object.values(instructions).forEach((x) => {
          if (!x.instruction[this.$i18n.locale]) {
            toTranslate[x.key] = x.instruction.en
          }
        })
        if (Object.values(toTranslate).length) {
          this.translateText(Object.values(toTranslate), this.$i18n.locale, v => v.forEach((v, i) => this.$set(instructions[Object.keys(toTranslate)[i]].instruction, this.$i18n.locale, v)))
        }

        // if (this.$i18n.locale != 'en' && !this.translations[this.$i18n.locale]) {
        //   this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
        //     source: "en",
        //     target: this.$i18n.locale,
        //     format: "text",
        //     q: [this.one_choice]
        //       .concat(Object.values(this.lab_result.interpretation.instructions))
        //       // .concat(this.lab_result.organisms.map(x => x.description))
        //       .concat(this.lab_result.resistance.map(x => x.description)),
        //   })
        //     .then((response) => {
        //       var translations = response.data.data.translations.map(x => x.translatedText)
        //       var instruction_keys = Object.keys(this.lab_result.interpretation.instructions)
        //       // var organism_keys = this.lab_result.organisms.map(x => x.name)
        //       var resistance_keys = this.lab_result.resistance.map(x => x.name.en)
        //       this.$set(this.translations, this.$i18n.locale, {
        //         one_choice: translations[0],
        //         instructions: instruction_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + 1] }), {}),
        //         // organisms: organism_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + instruction_keys.length + 1] }), {}),
        //         resistance: resistance_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + instruction_keys.length + 1] }), {}),
        //       })
        //     })
        // }
        // if (this.$i18n.locale != 'en' && this.lab_result.organisms.filter(x => x.description[this.$i18n.locale]).length != this.lab_result.organisms.filter(x => x.description).length) {
        //   var organisms = this.lab_result.organisms.map(x => x.description[this.$i18n.locale] ? null : x.description.en)
        //   this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
        //     source: "en",
        //     target: this.$i18n.locale,
        //     format: "text",
        //     q: organisms,
        //   })
        //     .then((response) => {
        //       var translations = response.data.data.translations.map(x => x.translatedText)
        //       for (var i = 0; i < translations.length; i++) {
        //         this.$set(this.lab_result.organisms[i].description, this.$i18n.locale, translations[i])
        //       }
        //     })
        // }
        // this.$vuetify.rtl = ['iw'].includes(this.$i18n.locale)
      },
      lab_result: {
        deep: true,
        handler () {
          if (this.disableOneChoice) {
            this.reload_matrix = true
            setTimeout(() => this.reload_matrix = false, 1150)
          }
        },
      },
      'lab_result.uuid' () {
        if (!this.selected_drug.id && this.lab_result.interpretation.one_choice) {
          this.selected_drug = this.lab_result.interpretation.one_choice.regimens[0].drug
        }
      },
    },
    created () {
      if (this.$route.params.hash) {
        this.fetchLabResult()
      }
    },
    mounted () {
      // if (this.lab_result && this.$i18n.locale != 'en' && !this.translations[this.$i18n.locale]) {
      //   this.translateText()
      // }
    },
    methods: {
      filtered_instructions (key) {
        let instructions = { ...this.instructions }
        delete instructions[key]
        return instructions
      },
      treatmentString (treatment) {
        let str = ''
        Object.values(treatment.regimens).forEach((r) => {
          str += r.drug.name.en + ' ' + r.dosage + ' x ' + r.duration
          if (r.pivot.connector) {
            str += ` ${r.pivot.connector} `
          }
        })
        return str += ' for possible ' + treatment.indication.indication
      },
      fetchLabResult () {
        this.loading.result = true
        const promise = this.axios.get('report/' + this.$route.params.hash)

        return promise.then((response) => {
          this.lab_result = response.data
          this.original_lab_result = { ...response.data }
        })
          .catch(error => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => { this.loading.result = false })
      },
      fetchPdf () {
        this.loading.pdf = true
        this.axios({
          url: 'report/' + this.lab_result.uuid + '/pdf',
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute('download', this.fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
          .catch(error => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => { this.loading.pdf = false })
      },
      // translateText () {
      //   this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
      //     source: "en",
      //     target: this.$i18n.locale,
      //     format: "text",
      //     q: Object.values(this.lab_result.interpretation.instructions).concat([this.one_choice]),
      //   })
      //     .then((response) => {
      //       var instructions = {}
      //       var keys = Object.keys(this.lab_result.interpretation.instructions)
      //       response.data.data.translations.map((instruction, index) => {
      //         instructions[keys[index]] = instruction.translatedText.replaceAll('/ img / ', '/img/').replaceAll(' .svg', '.svg')
      //       })
      //       this.$set(this.translations, this.$i18n.locale, {
      //         one_choice: response.data.data.translations.pop().translatedText,
      //         instructions: instructions,
      //       })
      //     })
      // },
    },
  }
</script>
<style lang="scss" scoped>
.branded {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1em;
}
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
</style>
<style>
.arkscore-pill {
  display: inline-block;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
